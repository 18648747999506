import {
  cloneElement,
  createContext,
  useContext,
  ReactElement,
  useState,
} from "react";
import { TNavContext } from "../types/context";

const NavContext = createContext<TNavContext>({
  sidebarShow: false,
  setSidebarShow: () => {},
});

const NavProvider = ({ children }: { children: ReactElement }) => {
  const [sidebarShow, setSidebarShow] = useState(true);
  const renderBody = (ele: ReactElement) => cloneElement(ele);

  return (
    <NavContext.Provider
      value={{
        sidebarShow,
        setSidebarShow,
      }}
    >
      {renderBody(children)}
    </NavContext.Provider>
  );
};

const useNav = () => useContext(NavContext);

export { NavProvider, useNav };
