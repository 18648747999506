import styled from "styled-components";

const Loader = () => (
  <LoaderWrapper
    className="flexCenter container x100"
    style={{ margin: "auto" }}
  >
    <div className="loader" />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-self: center;
  min-height: 250px;
`;

export default Loader;
