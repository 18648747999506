import moment from "moment";

const TODAY = "today";
const YESTERDAY = "yesterday";
const THIS_MONTH = "this_month";
const LAST_MONTH = "last_month";
const THIS_YEAR = "this_year";
const LAST_YEAR = "last_year";
const THIS_FY = "this_fy";
const LAST_FY = "last_fy";

export const getDataTypes = (_data) => {
  const types = {};
  Object.keys(_data).forEach((key) => {
    if (Date.parse(_data[key])) {
      types[key] = {
        type: "date string",
      };
    } else {
      types[key] = {
        type: !Number.isNaN(_data[key]) ? "number" : "string",
      };
    }
  });

  return types;
};

export const constructSilce = (_data) => {
  const slice: any = {};
  slice.rows = [
    {
      uniqueName: "id",
    },
  ];
  slice.columns = [
    {
      uniqueName: "Measures",
    },
  ];
  slice.measures = Object.keys(_data).map((item) => ({
    uniqueName: item,
    grandTotalCaption: item,
  }));

  return slice;
};

/** Format date in YYYY-MM-DD */
export const formatDate = (d) => {
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

/** Today's filter */
function todayFilter() {
  const d = new Date();
  return [formatDate(d), formatDate(d)];
}

/** Yesterday's filter */
function yesterdayFilter() {
  const d = new Date();
  return [
    formatDate(new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1)),
    formatDate(new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1)),
  ];
}

/** Handle custom filters i.e filters that are not pre-defined */
// function customFilter(duration) {
//   return {
//     start: formatDate(new Date(duration.start)),
//     end: formatDate(new Date(duration.end)),
//   };
// }

/** Create start and end date of the current (this) month */
function thisMonthFilter() {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return [formatDate(firstDay), formatDate(lastDay)];
}

/** Create stand and end dates for the last month */
const lastMonthFilter = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  return [formatDate(firstDay), formatDate(lastDay)];
};

/** Stand and end for this year */
function thisYear() {
  const nowDate = new Date();
  const firstDay = new Date(nowDate.getFullYear(), 0, 1);
  const lastDay = new Date(nowDate.getFullYear(), 11, 31);
  return [formatDate(firstDay), formatDate(lastDay)];
}

/** Stand and end for last year */
function lastYear() {
  const nowDate = new Date();
  const firstDay = new Date(nowDate.getFullYear() - 1, 0, 1);
  const lastDay = new Date(nowDate.getFullYear() - 1, 11, 31);
  return [formatDate(firstDay), formatDate(lastDay)];
}

/** Start and end for this financial year */
function thisFinancialYear() {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const last_financial_year_date = new Date(now.getFullYear(), 5, 30);
  let startYear;

  if (today.getTime() > last_financial_year_date.getTime()) {
    startYear = now.getFullYear();
  } else {
    startYear = now.getFullYear() - 1;
  }

  const endYear = startYear + 1;
  return [
    formatDate(new Date(startYear, 6, 1)),
    formatDate(new Date(endYear, 5, 30)),
  ];
}

/** Start and end for last financial year */
const lastFinancialYear = () => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const last_financial_year_date = new Date(now.getFullYear(), 5, 30);
  let startYear;

  if (today.getTime() > last_financial_year_date.getTime()) {
    startYear = now.getFullYear() - 1;
  } else {
    startYear = now.getFullYear() - 2;
  }

  const endYear = startYear + 1;
  return [
    formatDate(new Date(startYear, 6, 1)),
    formatDate(new Date(endYear, 5, 30)),
  ];
};

export const getPredefinedFilters = (value) => {
  switch (value) {
    case TODAY:
      return todayFilter();

    case YESTERDAY:
      return yesterdayFilter();

    case THIS_MONTH:
      return thisMonthFilter();

    case LAST_MONTH:
      return lastMonthFilter();

    case THIS_YEAR:
      return thisYear();

    case LAST_YEAR:
      return lastYear();

    case THIS_FY:
      return thisFinancialYear();

    case LAST_FY:
      return lastFinancialYear();

    default:
      return [null, null];
  }
};

export const customExport = (context, type) => {
  context.pivot.exportTo(type, {
    filename: "filename",
  });
};

export const customPdfExport = (context) => {
  const self = context;
  const { Labels } = context;
  const landscapeRadio = document.createElement("input");
  const label = document.createElement("label");
  const portraitRadio = document.createElement("input");
  let item = document.createElement("li");
  let wrap = document.createElement("div");

  const applyHandler = () => {
    let orientation = "portrait";
    if (landscapeRadio.checked) {
      orientation = "landscape";
    }
    self.pivot.exportTo("pdf", {
      pageOrientation: orientation,
      filename: "file_name",
    });
  };
  const dialog = context.popupManager.createPopup();
  dialog.setTitle(Labels.choose_page_orientation);
  dialog.setToolbar([
    {
      id: "wdr-btn-apply",
      label: Labels.apply,
      handler: applyHandler,
      isPositive: true,
    },
    { id: "wdr-btn-cancel", label: Labels.cancel },
  ]);

  const content = document.createElement("div");

  const list = document.createElement("ul");
  list.classList.add("wdr-radiobtn-list");
  content.appendChild(list);

  // portrait
  item = document.createElement("li");
  list.appendChild(item);
  wrap = document.createElement("div");
  wrap.classList.add("wdr-radio-wrap");
  item.appendChild(wrap);

  portraitRadio.id = "wdr-portrait-radio";
  portraitRadio.type = "radio";
  portraitRadio.name = "wdr-pdf-orientation";
  portraitRadio.checked = true;
  wrap.appendChild(portraitRadio);

  label.setAttribute("for", "wdr-portrait-radio");
  self.setText(label, Labels.portrait);
  wrap.appendChild(label);

  // landscape
  list.appendChild(item);
  wrap.classList.add("wdr-radio-wrap");
  item.appendChild(wrap);

  landscapeRadio.id = "wdr-landscape-radio";
  landscapeRadio.type = "radio";
  landscapeRadio.name = "wdr-pdf-orientation";
  wrap.appendChild(landscapeRadio);

  label.setAttribute("for", "wdr-landscape-radio");
  self.setText(label, Labels.landscape);
  wrap.appendChild(label);

  dialog.setContent(content);
  context.popupManager.addPopup(dialog.content);
};

export const customExportHandler = (context, type) => {
  if (type === "pdf") {
    customPdfExport(context);
  } else {
    customExport(context, type);
  }
};

export const reportDate = (start_date, end_date) => {
  const today = moment().format("YYYY-MM-DD");
  return start_date === today && end_date === today
    ? "Today"
    : `${start_date} to ${end_date}`;
};
