import styled from "styled-components";
import { primaryColor } from "../styled/Theme";

export const LogoText = () => {
  return (
    <p className="extraBold" style={{ fontSize: 28, marginBottom: 0 }}>
      NIFA<span className="mainColor">MIS</span>
    </p>
  );
};

export const ItemWrapper = styled.div`
  padding: 6px 10px;
  font-size: 13px;
  border-radius: 5px;
  transition: 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #013d1c;

  &:hover {
    background-color: #f2f2f2;
    color: ${primaryColor};
  }
`;

export const EmptyWrapper = styled.span`
  font-size: 12px;
  font-style: italic;
  color: #cccccc;
`;
