// colors

import { createTheme } from "@mui/material";

// export const primaryColor = "#78ffac";
export const primaryColor = "#009a44";
export const secondaryColor = "orange";
export const darkColor = "#212631";

export const antdTheme = {
  token: {
    colorPrimary: primaryColor,
  },
  components: {
    Button: {
      // primaryColor: "#000",
      // colorTextLightSolid: "#000",
      //   colorPrimaryHover: darkColor,
    },
  },
};

export const themeMui = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: primaryColor,
    },
  },
  typography: {
    // fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 13,
    button: {
      textTransform: "none",
    },
  },
});
