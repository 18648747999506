import { Select } from "antd";
import { TError, TOption } from "../../types/common";

type SelectInputType = {
  onChange?(value: number, option: TOption | TOption[]): void;
  label?: string;
  value: number | string | null;
  name: string;
  options: TOption[];
  error?: TError;
  multiple?: "multiple" | "tags";
  disabled?: boolean;
  required?: boolean;
};

const SelectInput = (props: SelectInputType) => {
  const { onChange, value, options, multiple, disabled, ...rest } = props;

  return (
    <div>
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        onChange={onChange}
        value={value as number}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        style={{ display: "block", width: "100%", height: 36 }}
        options={options}
        mode={multiple && "multiple"}
        disabled={disabled}
        size="middle"
        getPopupContainer={(node) => node.parentNode}
        {...rest}
      />
    </div>
  );
};

export default SelectInput;
