import { Menu } from "antd";
import { Link } from "react-router-dom";

const { SubMenu, Item } = Menu;

export function RecursiveMenu(data: any[]) {
  return data.map(
    ({
      key,
      label,
      icon,
      to,
      click,
      visible,
      children = [],
      hidden = false,
    }) => {
      if (hidden) return;
      if (visible) {
        if (!children?.length) {
          return (
            <Item key={key || Math.random()} icon={icon}>
              {to ? (
                <Link to={to} className="capitalize">
                  {label}
                </Link>
              ) : click ? (
                <div onClick={click} className="capitalize">
                  {label}
                </div>
              ) : (
                <div className="capitalize">{label}</div>
              )}
              {/* onClick */}
            </Item>
          );
        }

        return (
          <SubMenu
            style={{ paddingLeft: 0 }}
            popupOffset={[500, 500]}
            key={Math.random()}
            title={label}
            icon={icon}
          >
            {RecursiveMenu(children)}
          </SubMenu>
        );
      }

      // if unassigned visibility
      if (visible == null) {
        if (!children?.length) {
          return (
            <Item key={Math.random()} icon={icon}>
              {to ? (
                <Link to={to} className="capitalize">
                  {label}
                </Link>
              ) : click ? (
                <div onClick={click} className="capitalize">
                  {label}
                </div>
              ) : (
                <div className="capitalize">{label}</div>
              )}
              {/* onClick */}
            </Item>
          );
        }

        return (
          <SubMenu
            style={{ paddingLeft: 0 }}
            popupOffset={[500, 500]}
            key={Math.random()}
            title={label}
            icon={icon}
          >
            {RecursiveMenu(children)}
          </SubMenu>
        );
      }
    },
  );
}
