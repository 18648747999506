import { Add, Description } from "@mui/icons-material";
import { beautify, getForm, getRegister } from "../../utils/helpers";

export const createAddOption = (
  url: string,
  id?: string,
  prefix: string = "New",
  label?: string,
) => {
  const option = {
    label: label || `${prefix} ${beautify(id || url)}`,
    icon: <Add />,
    to: getForm(url),
  };
  return option;
};

export const createRegisterOption = (
  url: string,
  id?: string,
  status?: string,
  label?: string,
  suffix: string = "register",
) => {
  const option = {
    label: label || `${beautify(id)} ${suffix}`,
    icon: <Description />,
    to: getRegister(url, status),
  };
  return option;
};
