import { useState } from "react";
import useAxios from "./useAxios";
import { TLoadDataParams } from "../../types/common";

const useGet = () => {
  const [loading, setLoading] = useState(false);
  const [refreshing, setrefreshing] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<any>();
  const { get } = useAxios();

  const loadData = (options: TLoadDataParams) => {
    const { api, isExternal, params, method, id } = options;
    setData(null);
    setSuccess(false);
    setLoading(true);
    // If id is passed get single instance, other fetch list
    get({
      api: id ? `${api}${id}/` : api,
      params,
      isExternal,
      loader: setLoading,
      errorHandler: setError,
      successHandler: (data: any) => {
        setData(data);
        setSuccess(true);
      },
      methodName: method,
    });
  };

  const refreshData = (options: TLoadDataParams) => {
    const { api, isExternal, params } = options;
    setData(null);
    setrefreshing(true);
    // If id is passed get single instance, other fetch list
    get({
      api,
      isExternal,
      loader: setrefreshing,
      errorHandler: setError,
      successHandler: setData,
      params,
    });
  };

  return { loading, refreshing, error, data, success, loadData, refreshData };
};

export default useGet;
