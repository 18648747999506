import { CAvatar } from "@coreui/react";

import { getPhotoSource } from "../../utils/helpers";
import { useProfile } from "../../context/ProfileContext";
import styled from "styled-components";
import { Menu, Popover } from "antd";
import { accountSettings } from "../../constants/settings";
import { ActionsMenu } from "../common/FormActions";

const AppHeaderDropdown = () => {
  const { profile } = useProfile();

  return (
    <>
      <Popover
        content={
          <>
            <Menu mode="inline" className="font13 rightmenu">
              {ActionsMenu(accountSettings)}
            </Menu>
          </>
        }
        trigger="click"
        overlayStyle={{ minWidth: 200 }}
        overlayInnerStyle={{ padding: 5 }}
        placement="bottomRight"
      >
        <AvatarWrapper className="animate pointer">
          <CAvatar src={getPhotoSource(profile?.photo || "")} size="md" />
        </AvatarWrapper>
      </Popover>
    </>
  );
};

export default AppHeaderDropdown;

const AvatarWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
  &:hover {
    scale: 1.1;
  }
`;
