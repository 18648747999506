import { cloneElement } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { CloseCircleOutlined } from "@ant-design/icons";
import { PrimaryButton } from "../common/Buttons";

const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    minWidth: "60%",
    minHeight: "60%",
    maxHeight: "90%",
    maxWidth: "90%",
    borderRadius: 10,
  },
  "& .MuiDialogContent-root": {
    padding: useTheme().spacing(2),
    width: "100%",
    height: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: useTheme().spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: () => useTheme().palette.grey[500],
          }}
        >
          <CloseCircleOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const GenericModal = (props) => {
  const {
    show,
    dismiss,
    title,
    body,
    okText,
    cancelText,
    type,
    proceed,
    formId,
    hideActions,
  } = props;

  const onOkayClick = () => {
    if (!(type === "form" && formId)) {
      proceed();
    }
  };

  const renderBody = (ele, properties) => cloneElement(ele, properties);

  return (
    <BootstrapDialog
      onClose={dismiss}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      {title ? (
        <BootstrapDialogTitle onClose={() => {}}>
          <div className="panel panel-blue">
            <div className="blue-title">
              <div className="panel-title">{title}</div>
            </div>
          </div>
        </BootstrapDialogTitle>
      ) : null}
      <DialogContent dividers>{renderBody(body, props)}</DialogContent>
      {!hideActions ? (
        <DialogActions>
          <PrimaryButton theme="danger" title={cancelText} onClick={dismiss} />

          <PrimaryButton
            theme="primary"
            title={okText}
            style={{ marginLeft: "1em" }}
            onClick={onOkayClick}
            {...(formId ? { form: formId } : {})}
            type={type === "form" && formId ? "submit" : "button"}
          />

          <div style={{ clear: "both" }} />
        </DialogActions>
      ) : null}
    </BootstrapDialog>
  );
};

export default GenericModal;
