// GENERAL
export const API_CONFIGURABLE = "configurables/";
export const API_WATER_MANAGEMENT =
  "agricultural-water-management/agricultural-water-investments/";

// Food and Agriculture
export const API_FOOD_AND_AGRICULTURE = "nfas/data-collections/";

export const API_SEED_INSPECTION = "seeds/inspections/";
export const API_SEED_PRODUCERS = "seeds/producers/";
export const API_SEED_CERTIFICATION = "seeds/certifications/";
export const API_SEED_COMMERCIALIZATION = "seeds/commercializations/";
export const API_SEED_RETAIL = "seeds/retails/";
export const API_SEED_FARMER_USER = "seeds/farmer-uses/";
export const API_SEED_EXPORT = "seeds/exports/";

// USERS MANAGEMENT
export const API_PROFILE = "accounts/users/1";
export const API_USERS = "accounts/users/";
export const API_REGISTER_USER = "accounts/register-user/";
export const API_LOGIN = "token/";
export const API_RESET_PASSWORD = "password_reset/confirm/";
export const API_REQUEST_PASSWORD_RESET = "password_reset/";
export const API_ACCOUNTS_ROLES = "accounts/roles/";
export const API_ACCOUNTS_PROFILES = "accounts/profiles/";

//MICROSACLE IRRIGATION
export const API_MICROSACLE_IRRIGATION =
  "microscale-irrigation/microscale-irrigations/";

//LIVESTOCK_CERTIFICATION_API
export const API_LIVESTOCK_CERTIFICATION =
  "livestock-certifications/livestock-certifications/";

//FISH_CERTIFICATION_API
export const API_FISH_CERTIFICATION = "fisheries/";

//EXTENSIONS

export const API_EXTENSIONS_ANNUAL_WORK_PLAN = "e-extensions/annual-workplans/";
export const API_EXTENSIONS_QUARTERLY_ACTIVITIES =
  "e-extensions/quarterly-activities-records/";
export const API_EXTENSIONS_DAILY_ACTIVITIES =
  "e-extensions/daily-activities-records/";

export const API_EXTENSIONS_KNOWLEDGE_MANAGEMENT =
  "e-extensions/knowledge-resources/";

export const API_EXTENSIONS_WEATHER_DATA_ENTRY = "e-extensions/weather/";

export const API_EXTENSIONS_OUTBREAK_CRISIS =
  "e-extensions/outbreaks-and-crises/";

export const API_EXTENSIONS_WEATHER_ADVISORY = "e-extensions/weather-advisory/";

export const API_EXTENSIONS_GRIEVANCE_REDRESS_MECHANISMS =
  "e-extensions/grievance-reports/";

export const API_EXTENSIONS_FARMERS_PROFILE = "e-extensions/farmer-profilings/";

//AGRO CHEMICALS
export const API_AGROCHEM_SOURCE_TRACKING = "agro-chemicals/source-trackings/";
export const API_AGROCHEM_MANUFACTURING_FACILITIES =
  "agro-chemicals/manufacturing-facilities/";
export const API_AGROCHEM_MARKET_DISTRIBUTION =
  "agro-chemicals/market-distributions/";
export const API_AGROCHEM_EXPORT = "agro-chemicals/exports/";
export const API_AGROCHEM_USAGE = "agro-chemicals/usages/";

// MECHANISATION AND TECHNOLOGY
export const API_MECHANIZATION_TECH = "mechanization-technologies/";

//WAREHOUSE ASSET MANAGEMENT
export const API_WAREHOUSE_ASSET_MGT =
  "warehouse-asset-management/warehouse-asset-managements/";

//PHTOSANITARY
export const API_PHYTOSANITARY = "phytosanitaries/";

//LOCATION DETAILS
export const API_DISTRICTS = "districts/";
export const API_COUNTIES = "counties/";
export const API_DIVISIONS = "divisions/";
export const API_PARISHES = "parishes/";
export const API_VILLAGES = "villages/";
export const API_REGIONS = "regions/";

//CONFIGURABLES
export const API_CONFIGURABLES = "configurables/";

//VALIDATE NIN
export const VALIDATE_NIN_API = `accounts/register-user/validate-nin/`;

// AUDIT ACTIVITY
export const API_AUDIT_ACTIVITY = "audits/activity/";

// OFFICES
export const API_ACCOUNTS_DIRECTORATES = "accounts/directorates/";
export const API_ACCOUNTS_DEPARTMENTS = "accounts/departments/";
export const API_ACCOUNTS_DIVISIONS = "accounts/divisions/";

// REPORTS
export const API_REPORTS_DASHBOARD = "reports/dashboard/";

// PERMISSIONS
export const API_ACCOUNTS_PERMISSIONS = "accounts/permissions/";

// BILLINGS
export const API_BILLINGS_FEES = "billings/fees/";
