import React, {
  ReactElement,
  cloneElement,
  createContext,
  useContext,
  useState,
} from "react";
import { TGlobalContext } from "../types/context";

export const GlobalContext = createContext<TGlobalContext>({});

export const useGlobalContext = () => useContext(GlobalContext);

// Wrapper component to manage global state
export const GlobalProvider = ({ children }: { children: ReactElement }) => {
  const [data, setData] = useState<any>();
  const renderBody = (ele: ReactElement) => cloneElement(ele);

  const updateData = (newData: any) => {
    setData(newData);
  };

  const contextValue = { data, updateData };

  return (
    <GlobalContext.Provider value={contextValue}>
      {renderBody(children)}
    </GlobalContext.Provider>
  );
};
