import {
  cloneElement,
  createContext,
  useContext,
  // useEffect,
  ReactElement,
} from "react";
import { TProfileContext } from "../types/context";
// import useGet from "../hooks/common/useGet";
import { useAuth } from "./AuthContext";
// import { API_PROFILE } from "../utils/apis";

const ProfileContext = createContext<TProfileContext>({});

const ProfileProvider = ({ children }: { children: ReactElement }) => {
  // const { loading: loadingProfile, data: profile, loadData } = useGet();
  const {
    //  loggedIn,
    user,
  } = useAuth();

  const profile = user || undefined;

  // useEffect(() => {
  //   if (loggedIn) getProfile();
  // }, [loggedIn]);

  // const getProfile = () => {
  //   loadData({ id: user?.id, api: API_PROFILE });
  // };

  const renderBody = (ele: ReactElement) => cloneElement(ele);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        // loadingProfile,
        // getProfile,
      }}
    >
      {renderBody(children)}
    </ProfileContext.Provider>
  );
};

const useProfile = () => useContext(ProfileContext);

export { ProfileProvider, useProfile };
