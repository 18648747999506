import { useState } from "react";
import SelectInput from "../../common/SelectInput";
import { CONST_MODULES, CONST_PRNS } from "../../../utils/constants";

const SelectModuleForm = (props) => {
  const { formId, proceed, preValue } = props;
  const [currValue, setValue] = useState(preValue || CONST_PRNS);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ currValue });
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="row">
        <SelectInput
          options={(CONST_MODULES || []).map((sup) => ({
            label: sup.label,
            value: sup.value,
          }))}
          name="module"
          label="Module"
          onChange={(value) => {
            setValue(value);
          }}
          // placeholder="Select module"
          value={currValue}
          required
        />
      </div>
    </form>
  );
};

export default SelectModuleForm;
