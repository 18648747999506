import { ReactNode } from "react";

export const Visibility = ({
  children,
  show,
}: {
  children: ReactNode;
  show: boolean;
}) => {
  return show ? <>{children}</> : null;
};
