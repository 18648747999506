import mCoa from "./coa.png";
import mHeaderBg from "./header_bg.jpg";

import agrinvest from "./partners/agrinvest.png";
import eu from "./partners/eu.png";
import finance from "./partners/finance.png";
import food from "./partners/food.png";
import IsDb from "./partners/IsDb.png";
import ussia from "./partners/ussia.png";

export const CoaIMG = mCoa;
export const headerBgIMG = mHeaderBg;

export const agrinvestIMG = agrinvest;
export const euIMG = eu;
export const financeIMG = finance;
export const foodIMG = food;
export const IsDbIMG = IsDb;
export const ussiaIMG = ussia;
