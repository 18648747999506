import { useEffect } from "react";
import useGet from "../common/useGet";
import { API_USERS } from "../../utils/apis";
import { useAuth } from "../../context/AuthContext";
import { useLocalStorage } from "../common/useLocalStorage";
import { PROFILE } from "../../utils/constants";

export const useProfile = () => {
  const { user } = useAuth();
  const { loadData, data: profile, loading } = useGet();
  const { getData, setData } = useLocalStorage();

  const storedProfile = getData(PROFILE);

  useEffect(() => {
    if (!storedProfile && user?.id) {
      loadData({ api: `${API_USERS}${user?.id}` });
    }
  }, [storedProfile]);

  useEffect(() => {
    if (profile) {
      setData(PROFILE, profile);
    }
  }, [profile]);

  return { profile: storedProfile || profile, loading };
};
