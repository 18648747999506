import "./assets/scss/style.scss";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/AuthContext";
import { PrimeReactProvider } from "primereact/api";
import MainNavigation from "./components/navigation/MainNavigation";
import { NavProvider } from "./context/NavContext";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ProfileProvider } from "./context/ProfileContext";
import { ConfigProvider } from "antd";
import { antdTheme, themeMui } from "./components/styled/Theme";
import { ThemeProvider } from "@mui/material";
import { GlobalProvider } from "./context/GlobalContext";

const App = () => {
  return (
    <ThemeProvider theme={themeMui}>
      <ConfigProvider theme={antdTheme}>
        <PrimeReactProvider>
          <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              duration: 4000,
              success: {
                style: {
                  background: "green",
                  color: "white",
                  fontSize: 14,
                },
              },
              error: {
                style: {
                  background: "red",
                  color: "white",
                  fontSize: 14,
                },
              },
            }}
          />
          <GlobalProvider>
            <BrowserRouter>
              <AuthProvider>
                <ProfileProvider>
                  <NavProvider>
                    <MainNavigation />
                  </NavProvider>
                </ProfileProvider>
              </AuthProvider>
            </BrowserRouter>
          </GlobalProvider>
        </PrimeReactProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default App;
