import axios, { AxiosError } from "axios";
import { useAuth } from "../../context/AuthContext";
import { BASE_API_URL } from "../../config";

type TParams = {
  api: string;
  methodName?: string;
  loader?: (isloading: boolean) => void;
  successHandler?: (data: any) => void;
  errorHandler?: (error: string | any) => void;
  params?: object;
  isExternal?: boolean;
  type?: "application/json" | "multipart/form-data";
};

function useAxios() {
  const { authToken, logout } = useAuth();

  const get = async (options: TParams): Promise<any> => {
    const {
      api,
      loader,
      successHandler,
      isExternal,
      errorHandler,
      params,
      type,
    } = options;

    return await axios({
      method: "get",
      headers: {
        "Content-Type": type || "application/json",
        ...(!isExternal && authToken
          ? { Authorization: `Bearer ${authToken}` }
          : {}),
      },
      url: isExternal ? api : `${BASE_API_URL}${api}`,
      params,
    })
      .then((resp) => {
        const { data } = resp;

        if (successHandler) successHandler(data);
        if (loader) loader(false);
      })
      .catch((resp: AxiosError) => {
        if (resp.response?.status === 401) logout();

        if (errorHandler)
          errorHandler(resp?.message || "An unknown eror occured");
        if (loader) loader(false);
      });
  };

  const set = async (options: TParams): Promise<any> => {
    const {
      api,
      loader,
      successHandler,
      errorHandler,
      params,
      methodName,
      type,
    } = options;

    return await axios({
      headers: {
        "Content-Type": type || "application/json",
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      },
      method: methodName,
      url: `${BASE_API_URL}${api}`,
      data: params,
    })
      .then((resp) => {
        const { data } = resp;
        if (successHandler) successHandler(data);
        if (loader) loader(false);
      })
      .catch((resp: AxiosError) => {
        let message: string | object = "An unknown error occured";

        if (resp.response?.status === 401) {
          logout();
        } else if (resp.response?.status === 500) {
          message = "Internal server error occured";
        } else if (resp.response?.status === 402) {
          message = "Server timeout error occured";
        } else {
          message = resp?.response?.data || "An unknown error occured";
        }

        if (errorHandler) errorHandler(message);
        if (loader) loader(false);
      });
  };

  return { get, set };
}

export default useAxios;
