export const URL_WATER_MANAGEMENT = "/water_management";
export const URL_FOOD_AND_AGRICULTURE = "/register/food_and_agriculture";
export const URL_MECHANISATION_TECH = "/register/mechanisation_tech";
export const URL_WAREHOUSE_ASSET_MGT = "/register/warehouse_asset_management";
// AGRO CHEMICALS
export const URL_SOURCE_TRACKING = "/source_tracking";
export const URL_AGROCHEM_MANUFACTURING_FACILITIES =
  "/manufacturing_facilities";
export const URL_MARKET_DISTRIBUTION = "/market_distribution";
export const URL_PHYTOSANITARY = "/register/phytosanitary";
export const URL_USAGE = "/usage";
export const URL_EXPORT = "/export";
//SEEDS
export const URL_SEED_PRODUCERS = "/register/seed_producers";
export const URL_SEED_CERTIFICATION = "/register/seed_certification";
export const URL_SEED_COMMERCIALIZATION = "/register/seed_commercialization";
export const URL_SEED_RETAIL = "/register/seed_retail";
export const URL_SEED_INSPECTION = "/register/seed_inspection";
export const URL_SEED_FARMER_USE = "/register/seed_farmer_use";
export const URL_SEED_EXPORT = "/register/seed_export";

//MICROSACLE IRRIGATION
export const URL_MICROSACLE_IRRIGATION = "/register/microsacle_irrigation";
export const MICROSACLE_IRRIGATION = "/microsacle_irrigation";

//EXTENSIONS
export const URL_EXTENSION_ANNUAL_WORK_PLAN =
  "/register/extensions_annual_work_plans";
export const EXTENSION_ANNUAL_WORK_PLAN = "/extensions_annual_work_plans";

export const URL_EXTENSION_QUARTERLY_ACTIVITIES =
  "/register/extensions_quarterly_activities";
export const EXTENSION_QUARTERLY_ACTIVITIES =
  "/extensions_quarterly_activities";

export const URL_EXTENSION_DAILY_ACTIVITIES =
  "/register/extensions_daily_activities";
export const EXTENSION_DAILY_ACTIVITIES = "/extensions_daily_activities";

export const URL_EXTENSION_KNOWLEDGE_MANAGEMENT =
  "/register/extensions_knowledge_management";
export const EXTENSION_KNOWLEDGE_MANAGEMENT =
  "/extensions_knowledge_management";

export const URL_EXTENSION_WEATHER_DATA_ENTRY =
  "/register/extensions_weather_data_entry";
export const EXTENSION_WEATHER_DATA_ENTRY = "/extensions_weather_data_entry";

export const URL_EXTENSION_OUTBREAK_CRISIS =
  "/register/extensions_outbreak_crisis";
export const EXTENSION_OUTBREAK_CRISIS = "/extensions_outbreak_crisis";

export const URL_EXTENSION_WEATHER_ADVISORY =
  "/register/extensions_weather_advisory";
export const EXTENSION_WEATHER_ADVISORY = "/extensions_weather_advisory";

export const URL_EXTENSION_GRIEVANCE = "/register/extensions_grievances";
export const EXTENSION_GRIEVANCE = "/extensions_grievances";

export const URL_EXTENSION_FARMERS_PROFILE =
  "/register/extensions_farmers_profile";
export const EXTENSION_FARMERS_PROFILE = "/extensions_farmers_profile";

//SEED_URL
export const SEED_PRODUCERS = "/seed_producers";
export const SEED_CERTIFICATION = "/seed_certification";
export const SEED_INSPECTION = "/seed_inspection";
export const SEED_COMMERCIALIZATION = "/seed_commercialization";
export const SEED_RETAIL = "/seed_retail";
export const SEED_FARMER_USE = "/seed_farmer_use";

//LIVESTOCK CERTIFICATION
export const URL_CATTLE_TRADER_LICENSES = "/register/cattle_trader_licenses";
export const CATTLE_TRADER_LICENSES = "/cattle_trader_licenses";
export const URL_HIDES_AND_SKINS = "/register/hides_and_skins";
export const HIDES_AND_SKINS = "/hides_and_skins";
export const URL_IMPORT_PERMITS = "/register/import_permits";
export const IMPORT_PERMITS = "/import_permits";

//LIVESTOCK CERTIFICATION
export const URL_AQUACULTURE_ESTABLISHMENT_CERTIFICATES =
  "/register/aquaculture_establishment_certificates";
export const AQUACULTURE_ESTABLISHMENT_CERTIFICATES =
  "/aquaculture_establishment_certificates";
export const URL_FISH_EXPORTS_CERTIFICATES =
  "/register/fish_export_certificates";
export const FISH_EXPORTS_CERTIFICATES = "/fish_export_certificates";
export const URL_FISH_IMPORT_PRODUCTS_AQUACULTURE =
  "/register/fish_imports_products_aquaculture_permits";
export const FISH_IMPORT_PRODUCTS_AQUACULTURE =
  "/fish_imports_products_aquaculture_permits";

// USERS
export const URL_USERS = "/users";
export const URL_ROLES = "/roles";

// CONFIGURABLES
export const URL_CONFIGURABLES = "/configurables";

// MASTER DATA
export const URL_MASTER_DATA = "/master_data";
export const URL_REGIONS = "/regions";
export const URL_DISTRICTS = "/districts";
export const URL_COUNTIES = "/counties";
export const URL_DIVISIONS = "/divisions";
export const URL_PARISHES = "/parishes";
export const URL_VILLAGES = "/villages";

// AUDIT ACTIVITY
export const URL_AUDIT_ACTIVITY = "/audit_activity";

// OFFICES
export const URL_DIRECTORATES = "/directorates";
export const URL_DEPARTMENTS = "/departments";
export const URL_DEPARTMENT_DIVISIONS = "/department_divisions";

// PASSWORD RESET
export const URL_PASSWORD_RESET = "/password_reset";
export const URL_SETTINGS = "/settings";

// LOGIN
export const URL_LOGIN = "/login";
export const URL_REGISTER = "/register";

// FEED
export const URL_FEES = "/fees";

// Reports
export const URL_REPORTS = "/reports";
export const URL_ADHOC_REPORTS = "/adhoc_reports";
