import moment from "moment";
import { useEffect, useState } from "react";
import Flexmonster from "flexmonster";
import { customExportHandler, reportDate } from "../../../utils/adhocUtils";
import { openModal } from "../../../utils/modalUtils";
import FilterForm from "./FilterForm";
import SelectModuleForm from "./SelectModuleForm";
import useGet from "../../../hooks/common/useGet";
import {
  CONST_PRNS,
  END_DATE_FILTER,
  START_DATE_FILTER,
} from "../../../utils/constants";
import { FLEXMONSTER_KEY } from "../../../config";

const AdhocReports = () => {
  const { loadData, data } = useGet();
  const [module, setModule] = useState(CONST_PRNS);
  const [filter, setFilter] = useState({
    [START_DATE_FILTER]: moment().format("YYYY-MM-DD"),
    [END_DATE_FILTER]: moment().format("YYYY-MM-DD"),
  });

  const swapTab = (tabsParams, id, newTab) => {
    const tabs = tabsParams;
    let index = null;
    tabs.forEach((item, i) => {
      if (item.id === id) {
        index = i;
      }
    });

    if (index !== null) {
      tabs[index] = newTab;
    }

    return tabs;
  };

  const handleModule = () => {
    openModal({
      title: "Choose module",
      type: "form",
      formId: "module-form",
      body: (
        <SelectModuleForm
          setModule={setModule}
          module={module}
          preValue={module}
        />
      ),
      okText: "Apply",
    })
      .then(({ currValue }: any) => {
        setModule(currValue);
      })
      .catch(() => {});
  };

  function handleFilter() {
    openModal({
      title: "Select filter",
      type: "form",
      formId: "filter-form",
      body: <FilterForm />,
      okText: "Apply",
    })
      .then(({ currValue }: any) => {
        setFilter(currValue);
      })
      .catch(() => {});
  }

  const customizeToolbar = (toolbarParam) => {
    // Get all tabs
    const toolbar = toolbarParam;
    let tabs = toolbar.getTabs();

    toolbar.getTabs = function () {
      const customExportTab = {
        title: "Export",
        id: "fm-tab-export",
        mobile: false,
        icon: toolbarParam.icons.export,
        menu: [
          {
            title: toolbarParam.Labels.export_print,
            id: "fm-tab-export-print",
            handler: toolbarParam.printHandler,
            icon: toolbarParam.icons.export_print,
          },
          {
            title: toolbarParam.Labels.export_html,
            id: "fm-tab-export-html",
            handler: () => customExportHandler(toolbarParam, "html"),
            args: "html",
            icon: toolbarParam.icons.export_html,
          },
          {
            title: toolbarParam.Labels.export_excel,
            id: "fm-tab-export-excel",
            handler: () => customExportHandler(toolbarParam, "excel"),
            args: "excel",
            icon: toolbarParam.icons.export_excel,
          },
          {
            title: toolbarParam.Labels.export_pdf,
            id: "fm-tab-export-pdf",
            handler: () => customExportHandler(toolbarParam, "pdf"),
            args: "pdf",
            icon: toolbarParam.icons.export_pdf,
          },
        ],
      };

      tabs = swapTab(tabs, "fm-tab-export", customExportTab);

      // There will be two new tabs at the beginning of the toolbar
      tabs.unshift(
        {
          id: "fm-tab-lightblue",
          title: "Modules",
          handler: () => handleModule(),
          icon: toolbarParam.icons.fields,
        },
        {
          id: "fm-tab-default",
          title: "Filter",
          handler: () => handleFilter(),
          icon: toolbarParam.icons.format,
        },
      );

      return tabs;
    };
  };

  const displayReport = (report) => {
    new Flexmonster({
      container: "pivot-container",
      beforetoolbarcreated: customizeToolbar,
      toolbar: true,
      global: {
        options: {
          defaultDateType: "date string",
          datePattern: "dd/MM/yyyy",
        },
      },
      licenseKey: FLEXMONSTER_KEY,
      componentFolder: "https://cdn.flexmonster.com/",
      report: {
        dataSource: {
          data: report,
        },
        options: {
          grid: {
            showGrandTotals: "columns",
            title: `${String(module).toUpperCase()} - ${reportDate(
              filter[START_DATE_FILTER],
              filter[END_DATE_FILTER],
            )}`,
            type: "flat",
          },
        },
      },
    });
  };

  useEffect(() => {
    loadData({ api: `${module}-adhoc/`, params: filter });
  }, [module, filter]);

  useEffect(() => {
    if (data) displayReport(Array.isArray(data) ? data : []);
  }, [data]);

  return (
    <div className="App">
      <div id="pivot-container" />
    </div>
  );
};

export default AdhocReports;
