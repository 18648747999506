import { Menu } from "antd";
import { primaryColor } from "../styled/Theme";
import { RecursiveMenu } from "../menu/RecursiveMenu";
import {
  URL_AQUACULTURE_ESTABLISHMENT_CERTIFICATES,
  URL_CATTLE_TRADER_LICENSES,
  URL_EXPORT,
  URL_EXTENSION_ANNUAL_WORK_PLAN,
  URL_EXTENSION_DAILY_ACTIVITIES,
  URL_EXTENSION_FARMERS_PROFILE,
  URL_EXTENSION_GRIEVANCE,
  URL_EXTENSION_KNOWLEDGE_MANAGEMENT,
  URL_EXTENSION_OUTBREAK_CRISIS,
  URL_EXTENSION_QUARTERLY_ACTIVITIES,
  URL_EXTENSION_WEATHER_ADVISORY,
  URL_EXTENSION_WEATHER_DATA_ENTRY,
  URL_FISH_EXPORTS_CERTIFICATES,
  URL_FISH_IMPORT_PRODUCTS_AQUACULTURE,
  URL_FOOD_AND_AGRICULTURE,
  URL_HIDES_AND_SKINS,
  URL_IMPORT_PERMITS,
  URL_MARKET_DISTRIBUTION,
  URL_AGROCHEM_MANUFACTURING_FACILITIES,
  URL_MECHANISATION_TECH,
  URL_MICROSACLE_IRRIGATION,
  URL_PHYTOSANITARY,
  URL_REPORTS,
  URL_SEED_CERTIFICATION,
  URL_SEED_COMMERCIALIZATION,
  URL_SEED_EXPORT,
  URL_SEED_FARMER_USE,
  URL_SEED_INSPECTION,
  URL_SEED_PRODUCERS,
  URL_SEED_RETAIL,
  URL_SOURCE_TRACKING,
  URL_USAGE,
  URL_WAREHOUSE_ASSET_MGT,
  URL_WATER_MANAGEMENT,
} from "../../utils/urls";
import {
  Description,
  Factory,
  Coronavirus,
  ScienceOutlined,
  WaterDropOutlined,
  Settings,
  Shower,
  Extension,
  Warehouse,
  Engineering,
  Sanitizer,
  Pets,
  Report,
  SetMeal,
} from "@mui/icons-material";
import { getForm } from "../../utils/helpers";
import { settings } from "../../constants/settings";
import { useGlobalContext } from "../../context/GlobalContext";
import { Grid, RestaurantOutline } from "@raresail/react-ionicons";

function LeftMenuItems(): any {
  const menu: any = [
    {
      label: "Dashboard",
      to: "/",
      visible: true,
      key: "default",
      icon: <Grid />,
    },
    // Food and Agriculture
    {
      label: "Food and Agriculture",
      to: URL_FOOD_AND_AGRICULTURE,
      icon: <RestaurantOutline />,
      children: [
        {
          label: "New Application",
          to: getForm("food_and_agriculture"),
        },
        {
          label: "Submitted Collections",
          to: `${URL_FOOD_AND_AGRICULTURE}/submitted`,
        },
        {
          label: "Approved Collections",
          to: `${URL_FOOD_AND_AGRICULTURE}/approved`,
        },
        {
          label: "Deffered Collections",
          to: `${URL_FOOD_AND_AGRICULTURE}/deffered`,
        },
      ],
    },
    //IRRIGATION
    {
      label: "Microsacle Irrigation",
      to: URL_MICROSACLE_IRRIGATION,
      icon: <Shower />,
      children: [
        {
          label: "New Application",
          to: getForm("microsacle_irrigation"),
        },
        {
          label: "Submitted Register",
          to: `${URL_MICROSACLE_IRRIGATION}/submitted`,
        },
        {
          label: "Pending Approval Register",
          to: `${URL_MICROSACLE_IRRIGATION}/pending_approval`,
        },
        {
          label: "Approved Register",
          to: `${URL_MICROSACLE_IRRIGATION}/approved`,
        },
        {
          label: "Deffered Register",
          to: `${URL_MICROSACLE_IRRIGATION}/deffered`,
        },
        {
          label: "Rejected Register",
          to: `${URL_MICROSACLE_IRRIGATION}/rejected`,
        },
      ],
    },
    {
      label: "Mechanisation Technologies",
      to: URL_MECHANISATION_TECH,
      icon: <Engineering />,
      children: [
        {
          label: "New Application",
          to: getForm("mechanisation_tech"),
        },
        {
          label: "Submitted Register",
          to: `${URL_MECHANISATION_TECH}/submitted`,
        },
        {
          label: "Pending Approval Register",
          to: `${URL_MECHANISATION_TECH}/pending_approval`,
        },
        {
          label: "Approved Register",
          to: `${URL_MECHANISATION_TECH}/approved`,
        },
        {
          label: "Deffered Register",
          to: `${URL_MECHANISATION_TECH}/deffered`,
        },
        {
          label: "Rejected Register",
          to: `${URL_MECHANISATION_TECH}/rejected`,
        },
      ],
    },
    // Phytosanitary
    {
      label: "Phytosanitary",
      to: URL_PHYTOSANITARY,
      icon: <Sanitizer />,
      children: [
        {
          label: "New Application",
          to: getForm("phytosanitary"),
        },
        {
          label: "Submitted Register",
          to: `${URL_PHYTOSANITARY}/submitted`,
        },
        {
          label: "Pending Approval Register",
          to: `${URL_PHYTOSANITARY}/pending_approval`,
        },
        {
          label: "Approved Register",
          to: `${URL_PHYTOSANITARY}/approved`,
        },
        {
          label: "Deffered Register",
          to: `${URL_PHYTOSANITARY}/deffered`,
        },
        {
          label: "Rejected Register",
          to: `${URL_PHYTOSANITARY}/rejected`,
        },
      ],
    },

    // Livestock Certification
    {
      label: "Livestock Certification",
      icon: <Pets />,
      children: [
        {
          label: "Cattle Trader License",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("cattle_trader_licenses"),
            },
            {
              label: "Submitted Register",
              to: `${URL_CATTLE_TRADER_LICENSES}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_CATTLE_TRADER_LICENSES}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_CATTLE_TRADER_LICENSES}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_CATTLE_TRADER_LICENSES}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_CATTLE_TRADER_LICENSES}/deferred`,
            },
          ],
        },
        {
          label: "Hides & Skins Exporters Buyers License",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("hides_and_skins"),
            },
            {
              label: "Submitted Register",
              to: `${URL_HIDES_AND_SKINS}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_HIDES_AND_SKINS}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_HIDES_AND_SKINS}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_HIDES_AND_SKINS}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_HIDES_AND_SKINS}/deferred`,
            },
          ],
        },
        {
          label: "Animal Products Import Permit",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("import_permits"),
            },
            {
              label: "Submitted Register",
              to: `${URL_IMPORT_PERMITS}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_IMPORT_PERMITS}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_IMPORT_PERMITS}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_IMPORT_PERMITS}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_IMPORT_PERMITS}/deferred`,
            },
          ],
        },
      ],
    },

    // Fisheries Certification
    {
      label: "Fisheries Certification",
      icon: <SetMeal />,
      children: [
        {
          label: "Aquaculture Establishments Certificate",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("aquaculture_establishment_certificates"),
            },
            {
              label: "Submitted Register",
              to: `${URL_AQUACULTURE_ESTABLISHMENT_CERTIFICATES}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_AQUACULTURE_ESTABLISHMENT_CERTIFICATES}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_AQUACULTURE_ESTABLISHMENT_CERTIFICATES}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_AQUACULTURE_ESTABLISHMENT_CERTIFICATES}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_AQUACULTURE_ESTABLISHMENT_CERTIFICATES}/deferred`,
            },
          ],
        },
        {
          label: "Fish Exports Certificates",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("fish_export_certificates"),
            },
            {
              label: "Submitted Register",
              to: `${URL_FISH_EXPORTS_CERTIFICATES}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_FISH_EXPORTS_CERTIFICATES}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_FISH_EXPORTS_CERTIFICATES}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_FISH_EXPORTS_CERTIFICATES}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_FISH_EXPORTS_CERTIFICATES}/deferred`,
            },
          ],
        },
        {
          label: "Imports or Products or Aquaculture Inputs Permits",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("fish_imports_products_aquaculture_permits"),
            },
            {
              label: "Submitted Register",
              to: `${URL_FISH_IMPORT_PRODUCTS_AQUACULTURE}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_FISH_IMPORT_PRODUCTS_AQUACULTURE}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_FISH_IMPORT_PRODUCTS_AQUACULTURE}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_FISH_IMPORT_PRODUCTS_AQUACULTURE}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_FISH_IMPORT_PRODUCTS_AQUACULTURE}/deferred`,
            },
          ],
        },
      ],
    },
    // Seeds Management
    {
      label: "Seeds Management",
      icon: <Coronavirus />,
      children: [
        {
          label: "Seed Producers ",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("seed_producers"),
            },
            {
              label: "Submitted Register",
              to: `${URL_SEED_PRODUCERS}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_SEED_PRODUCERS}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_SEED_PRODUCERS}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_SEED_PRODUCERS}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_SEED_PRODUCERS}/deferred`,
            },
          ],
        },
        {
          label: "Seed Certification",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("seed_certification"),
            },
            {
              label: "Submitted Register",
              to: `${URL_SEED_CERTIFICATION}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_SEED_CERTIFICATION}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_SEED_CERTIFICATION}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_SEED_CERTIFICATION}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_SEED_CERTIFICATION}/deferred`,
            },
          ],
        },
        {
          label: "Seed Inspection",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("seed_inspection"),
            },
            {
              label: "Submitted Register",
              to: `${URL_SEED_INSPECTION}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_SEED_INSPECTION}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_SEED_INSPECTION}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_SEED_INSPECTION}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_SEED_INSPECTION}/deferred`,
            },
          ],
        },
        {
          label: "Seed Commercialization",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("seed_commercialization"),
            },
            {
              label: "Submitted Register",
              to: `${URL_SEED_COMMERCIALIZATION}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_SEED_COMMERCIALIZATION}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_SEED_COMMERCIALIZATION}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_SEED_COMMERCIALIZATION}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_SEED_COMMERCIALIZATION}/deferred`,
            },
          ],
        },
        {
          label: "Seed Retail",
          icon: <Description />,

          children: [
            {
              label: "New Application",
              to: getForm("seed_retail"),
            },
            {
              label: "Submitted Register",
              to: `${URL_SEED_RETAIL}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_SEED_RETAIL}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_SEED_RETAIL}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_SEED_RETAIL}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_SEED_RETAIL}/deferred`,
            },
          ],
        },
        {
          label: "Seed Use",
          icon: <Description />,

          children: [
            {
              label: "New Application",
              to: getForm("seed_farmer_use"),
            },
            {
              label: "Submitted Register",
              to: `${URL_SEED_FARMER_USE}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_SEED_FARMER_USE}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_SEED_FARMER_USE}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_SEED_FARMER_USE}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_SEED_FARMER_USE}/deferred`,
            },
          ],
        },
        {
          label: "Seed Export",
          icon: <Description />,

          children: [
            {
              label: "New Application",
              to: getForm("seed_export"),
            },
            {
              label: "Submitted Register",
              to: `${URL_SEED_EXPORT}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_SEED_EXPORT}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_SEED_EXPORT}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_SEED_EXPORT}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_SEED_EXPORT}/deferred`,
            },
          ],
        },
      ],
    },
    // Agro Chemicals
    {
      label: "Agro Chemicals",
      icon: <ScienceOutlined />,
      children: [
        {
          label: "Source Tracking",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: `${URL_SOURCE_TRACKING}/create`,
            },
            {
              label: "Submitted  Register",
              to: `/register${URL_SOURCE_TRACKING}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `/register${URL_SOURCE_TRACKING}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `/register${URL_SOURCE_TRACKING}/approved`,
            },
            {
              label: "Rejected Register",
              to: `/register${URL_SOURCE_TRACKING}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `/register${URL_SOURCE_TRACKING}/deferred`,
            },
          ],
        },
        {
          label: "Manufacturing Facilities",
          icon: <Factory />,
          children: [
            {
              label: "New Application",
              to: `${URL_AGROCHEM_MANUFACTURING_FACILITIES}/create`,
            },
            {
              label: "Submitted  Register",
              to: `/register${URL_AGROCHEM_MANUFACTURING_FACILITIES}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `/register${URL_AGROCHEM_MANUFACTURING_FACILITIES}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `/register${URL_AGROCHEM_MANUFACTURING_FACILITIES}/approved`,
            },
            {
              label: "Rejected Register",
              to: `/register${URL_AGROCHEM_MANUFACTURING_FACILITIES}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `/register${URL_AGROCHEM_MANUFACTURING_FACILITIES}/deferred`,
            },
          ],
        },
        {
          label: "Market Distribution",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: `${URL_MARKET_DISTRIBUTION}/create`,
            },
            {
              label: "Submitted  Register",
              to: `/register${URL_MARKET_DISTRIBUTION}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `/register${URL_MARKET_DISTRIBUTION}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `/register${URL_MARKET_DISTRIBUTION}/approved`,
            },
          ],
        },
        {
          label: "Usage",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: `${URL_USAGE}/create`,
            },
            {
              label: "Submitted  Register",
              to: `/register${URL_USAGE}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `/register${URL_USAGE}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `/register${URL_USAGE}/approved`,
            },
          ],
        },
        {
          label: "Export",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: `${URL_EXPORT}/create`,
            },
            {
              label: "Submitted  Register",
              to: `/register${URL_EXPORT}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `/register${URL_EXPORT}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `/register${URL_EXPORT}/approved`,
            },
          ],
        },
      ],
    },

    //EXTENSIONS
    {
      label: "Extensions",
      icon: <Extension />,
      children: [
        {
          label: "Annual Work Plans",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_annual_work_plans"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_ANNUAL_WORK_PLAN}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_ANNUAL_WORK_PLAN}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_ANNUAL_WORK_PLAN}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_ANNUAL_WORK_PLAN}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_ANNUAL_WORK_PLAN}/deferred`,
            },
          ],
        },
        {
          label: "Quarterly Activities",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_quarterly_activities"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_QUARTERLY_ACTIVITIES}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_QUARTERLY_ACTIVITIES}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_QUARTERLY_ACTIVITIES}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_QUARTERLY_ACTIVITIES}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_QUARTERLY_ACTIVITIES}/deferred`,
            },
          ],
        },
        {
          label: "Daily Activities",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_daily_activities"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_DAILY_ACTIVITIES}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_DAILY_ACTIVITIES}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_DAILY_ACTIVITIES}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_DAILY_ACTIVITIES}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_DAILY_ACTIVITIES}/deferred`,
            },
          ],
        },
        {
          label: "Knowledge Management",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_knowledge_management"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_KNOWLEDGE_MANAGEMENT}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_KNOWLEDGE_MANAGEMENT}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_KNOWLEDGE_MANAGEMENT}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_KNOWLEDGE_MANAGEMENT}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_KNOWLEDGE_MANAGEMENT}/deferred`,
            },
          ],
        },
        {
          label: "Weather Data Entry",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_weather_data_entry"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_WEATHER_DATA_ENTRY}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_WEATHER_DATA_ENTRY}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_WEATHER_DATA_ENTRY}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_WEATHER_DATA_ENTRY}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_WEATHER_DATA_ENTRY}/deferred`,
            },
          ],
        },
        {
          label: "Weather Advisory",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_weather_advisory"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_WEATHER_ADVISORY}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_WEATHER_ADVISORY}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_WEATHER_ADVISORY}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_WEATHER_ADVISORY}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_WEATHER_ADVISORY}/deferred`,
            },
          ],
        },
        {
          label: "Outbreak Crisis",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_outbreak_crisis"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_OUTBREAK_CRISIS}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_OUTBREAK_CRISIS}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_OUTBREAK_CRISIS}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_OUTBREAK_CRISIS}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_OUTBREAK_CRISIS}/deferred`,
            },
          ],
        },
        {
          label: "Grievances Reporting",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_grievances"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_GRIEVANCE}/submitted`,
            },
            {
              label: "Assigned Register",
              to: `${URL_EXTENSION_GRIEVANCE}/assigned`,
            },
            {
              label: "Resolved Register",
              to: `${URL_EXTENSION_GRIEVANCE}/resolved`,
            },

            {
              label: "Overdue Register",
              to: `${URL_EXTENSION_GRIEVANCE}/overdue`,
            },
          ],
        },
        {
          label: "Farmers Profiling",
          icon: <Description />,
          children: [
            {
              label: "New Application",
              to: getForm("extensions_farmers_profile"),
            },
            {
              label: "Submitted Register",
              to: `${URL_EXTENSION_FARMERS_PROFILE}/submitted`,
            },
            {
              label: "Pending Approval Register",
              to: `${URL_EXTENSION_FARMERS_PROFILE}/pending_approval`,
            },
            {
              label: "Approved Register",
              to: `${URL_EXTENSION_FARMERS_PROFILE}/approved`,
            },
            {
              label: "Rejected Register",
              to: `${URL_EXTENSION_FARMERS_PROFILE}/rejected`,
            },
            {
              label: "Deferred Register",
              to: `${URL_EXTENSION_FARMERS_PROFILE}/deferred`,
            },
          ],
        },
      ],
    },
    // WATER MANAGEMENT
    {
      label: "Water Management",
      to: URL_WATER_MANAGEMENT,
      icon: <WaterDropOutlined />,
      children: [
        {
          label: "New Application",
          to: `${URL_WATER_MANAGEMENT}/create`,
        },
        {
          label: "Submitted Applications",
          to: `/register${URL_WATER_MANAGEMENT}/submitted`,
        },
        {
          label: "Approved Applications",
          to: `/register${URL_WATER_MANAGEMENT}/approved`,
        },
        {
          label: "Rejected Applications",
          to: `/register${URL_WATER_MANAGEMENT}/rejected`,
        },
        {
          label: "Deffered Applications",
          to: `/register${URL_WATER_MANAGEMENT}/deffered`,
        },
      ],
    },
    {
      label: "Warehouse Asset Management",
      to: URL_WAREHOUSE_ASSET_MGT,
      icon: <Warehouse />,
      children: [
        {
          label: "New Application",
          to: getForm("warehouse_asset_management"),
        },
        {
          label: "Submitted Register",
          to: `${URL_WAREHOUSE_ASSET_MGT}/submitted`,
        },
        {
          label: "Pending Approval Register",
          to: `${URL_WAREHOUSE_ASSET_MGT}/pending_approval`,
        },
        {
          label: "Approved Register",
          to: `${URL_WAREHOUSE_ASSET_MGT}/approved`,
        },
        {
          label: "Deffered Register",
          to: `${URL_WAREHOUSE_ASSET_MGT}/deffered`,
        },
        {
          label: "Rejected Register",
          to: `${URL_WAREHOUSE_ASSET_MGT}/rejected`,
        },
      ],
    },
    // Reports
    {
      label: "Reports",
      to: URL_REPORTS,
      icon: <Report />,
      children: [
        {
          label: "Adhoc Reports",
          to: "reports/adhoc-reports",
        },
      ],
    },
    // SETTINGS
    {
      icon: <Settings />,
      label: "Settings",
      to: "/settings",
    },
  ];
  return menu;
}

export const LeftMenu = () => {
  const { data } = useGlobalContext();
  const settingsItems = settings();
  const leftMenuItems = LeftMenuItems();
  return (
    <Menu
      className="font13 leftmenu radius6"
      mode="inline"
      defaultSelectedKeys={["default"]}
      //   theme="dark"
      style={{
        border: "none",
        backgroundColor: primaryColor,
      }}
    >
      {RecursiveMenu(data?.showSettings ? settingsItems : leftMenuItems)}
    </Menu>
  );
};
