import {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useState,
  ReactElement,
} from "react";
import { TAuthContext, TCredentials, TUser } from "../types/context";
import {
  decodeUser,
  getCredentials,
  removeCredentials,
  setCredentials,
} from "../utils/helpers";

const AuthContext = createContext<TAuthContext>({
  loggedIn: false,
  login: () => {},
  logout: () => {},
  updateUser: () => {},
  user: null,
  gettingAuth: true,
  authToken: "",
});

const AuthProvider = ({ children }: { children: ReactElement }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState<TUser | null>(null);
  const [authToken, setAuthToken] = useState<string>("");
  const [gettingAuth, setGettingAuth] = useState(true);

  useEffect(() => {
    const fetchCredentials = () => {
      const res = getCredentials();

      if (res) {
        setLoggedIn(true);
        setUser(decodeUser(res.access));
        setAuthToken(res.access);
      }
      setGettingAuth(false);
    };

    fetchCredentials();
  }, []);

  const login = (credentials: TCredentials) => {
    setCredentials(credentials);
    setUser(decodeUser(credentials.access));
    setAuthToken(credentials.access);
    setLoggedIn(true);
  };

  const logout = () => {
    removeCredentials();
    setUser(null);
    setLoggedIn(false);
  };

  const updateUser = (user: TUser) => {
    setCredentials({ access: authToken, user });
    setUser(user);
  };

  const renderBody = (ele: ReactElement) => cloneElement(ele);

  return (
    <AuthContext.Provider
      value={{
        login,
        loggedIn: loggedIn,
        logout,
        user,
        gettingAuth,
        authToken,
        updateUser,
      }}
    >
      {renderBody(children)}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

const LogoutRoute = () => <>{useAuth().logout()}</>;

export { AuthProvider, useAuth, LogoutRoute };
