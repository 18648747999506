import { lazy } from "react";

const Login = lazy(() => import("./LoginPage"));
const RegisterPage = lazy(() => import("./RegisterPage"));
const Landing = lazy(() => import("../landing/Landing"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));

const AuthRoutes = [
  {
    path: "/",
    component: <Landing />,
    exact: true,
  },
  {
    path: "/login",
    component: <Login />,
    exact: true,
  },
  {
    path: "/register",
    component: <RegisterPage />,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
    exact: true,
  },
  {
    path: "/password-reset/:token",
    component: <ResetPassword />,
    exact: true,
  },
];

export default AuthRoutes;
