import { DatePicker } from "antd";
import { DateInputProps } from "../../types/common";

export const DateInput = (props: DateInputProps) => {
  const { value, disabledDate, onChange, required, ...rest } = props;

  return (
    <DatePicker
      onChange={(date: any) => onChange(date)}
      value={value}
      style={{ width: "100%", height: 36 }}
      getPopupContainer={(node: HTMLElement): HTMLElement =>
        node.parentNode as HTMLElement
      }
      disabledDate={disabledDate as any}
      required={required}
      {...rest}
    />
  );
};
