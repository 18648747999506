import { GenericObjectArrayType } from "../types/common";

// CONFIGURABLE KEYS
export const RESPONDENT_CATEGORY = "respondent_category";
export const CLASSIFICATION_ACCESSIBILITY = "classification_accessiblity";
export const CLASSIFICATION_STORAGE = "classification_storage";
export const CLASSIFICATION_DISSEMINATION = "classification_dissemination";
export const CONST_WATER_MANAGEMENT = "water_management";
// MECHANISATION TECH
export const MECHANISED_TECHNOLOGY_TYPE = "mechanised_technology_type";
export const MECHANISED_POWER_SOURCE = "mechanised_power_source";
export const MECHANISED_FUEL_CONSUMPTION = "mechanised_fuel_consumption";
export const MECHANISED_SUPPLIER_TYPE = "mechanised_supplier_type";
export const MECHANISED_SERVICE_PROVIDED = "mechanised_maintenance_services";
// Warehouse Asset Management
export const WAREHOUSE_DIRECTORATE = "warehouse_directorate";
export const WAREHOUSE_DEPARTMENT = "warehouse_department";
export const WAREHOUSE_CATEGORY_OF_INVENTORY =
  "warehouse_category_of_inventory";
export const WAREHOUSE_NATURE_OF_INVENTORY = "warehouse_nature_of_inventory";

// PHYTOSANITARY
export const PHYTOSANITARY_PRODUCE_TYPES = "phytosanitary_produce_type";
export const PHYTOSANITARY_INSPECTION_TYPES = "phytosanitary_inspection_type";
export const PHYTOSANITARY_PROCESSING_METHODS =
  "phytosanitary_processing_methods";
export const PHYTOSANITARY_DESTINATION_COUNTRIES = "destination_country";

// AGROCHEMICALS
export const AGROCHEMICALS_MEASUREMENT_UNIT = "measurement_unit";
export const AGROCHEMICALS_CROP_TYPE = "crop_type";
export const AGROCHEMICALS_FINAL_PRODUCT_TYPE = "final_product_type";
export const AGROCHEMICALS_RAW_MATERIAL_TYPE = "raw_material_type";

//MICROSACLE IRRIGATION
export const IRRIGATION_SYSTEM_TYPE =
  "microscale_irrigation_irrigation_system_type";
export const NATURE_OF_RAIN_SPRAY =
  "microscale_irrigation_rain_spray_pipe_nature";
export const ASSESSORIES_TYPE = "microscale_irrigation_accessories_type";
export const LAND_TOPOGRAPHY = "microscale_irrigation_land_topography";
export const WATER_QUALITY_TYPES = "microscale_irrigation_water_quality";
export const WATER_DELIVERY_TYPES =
  "microscale_irrigation_water_delivery_mechanism";
export const WATER_APPLICATION_RATE_TYPES =
  "microscale_irrigation_water_application_rate";

export const DAILY_ACTIVITIES_BENEFICIARY_GROUP =
  "daily_activities_beneficiary_group";
export const DAILY_ACTIVITIES_ENTERPRISE_COVERED =
  "daily_activities_enterprise_covered";

export const KNOWLEDGE_RESOURCE_ENTERPRISE_APPROACH =
  "knowledge_resource_enterprise_or_approach";

export const KNOWLEDGE_RESOURCE_LANGUAGE = "knowledge_resource_language";

export const WEATHER_ADVISORY_TYPE = "weather_advisory_weather_advisory_type";

export const WEATHER_TARGET_TYPE = " weather_advisory_target_users";

export const KNOWLEDGE_RESOURCE_CONTENT_TYPE =
  "knowledge_resource_content_type";

export const OUTBREAK_AND_CRISIS_ENTERPRISE_AFFECTED =
  "outbreak_and_crisis_enterprise_affected";
export const OUTBREAK_AND_CRISIS_EVENT_TYPE = "outbreak_and_crisis_event_type";

export const OUTBREAK_AND_CRISIS_CRITICALITY =
  "outbreak_and_crisis_criticality";
export const OUTBREAK_AND_CRISIS_SPECIES_AFFECTED =
  "outbreak_and_crisis_species_affected";

export const GRIEVANCE_REPORT_GRIEVANCE_CATEGORY =
  "grievance_report_grievance_category";

export const GRIEVANCE_REPORT_PRIORITY = "grievance_report_priority";

export const EXPORTER_OCCUPATION = "exporter_occupation";
export const ENTRY_PORT = "entry_port";
export const COVEYANCE_TYPE = "conveyance_type";
export const PRODUCT_TYPE = "product_type";
export const PACKAGE_TYPE = "package_type";
export const CURRENCY = "currency";

export const CONST_CREDENTIALS_KEY = "DGD^#&#^#*#&#*(^GDGE#^*&*%%%";

export const colors = {
  THEME_COLOR: "#364174",
  BUTTON: "#009a44",
  WHITE: "#ffffff",
};

export const BOOLEAN_OPTIONS: GenericObjectArrayType[] = [
  { id: 0, name: "No" },
  { id: 1, name: "Yes" },
];

export const MALE = "Male";
export const FEMALE = "Female";

// DATE FORMATS
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_YEAR = "YYYY";
export const DATE_TIME_FORMAT = "YYYY-MM-DD, HH:mm A";

// STATUS
export const PENDING = "pending";
export const SUBMITTED = "submitted";
export const PENDING_APPROVAL = "pending_approval";
export const REJECTED = "rejected";
export const APPROVED = "approved";
export const DEFERRED = "deferred";
export const FOWARDED = "fowarded";
export const ASSIGNED = "assign";
export const RESOLVED = "resolve";
export const OVERDUED = "overdue";

// LABELS
export const VIEW = "View";
export const EDIT = "Edit";

// MASTER DATA TYPES
export const REGION = "region";
export const DISTRICT = "district";
export const PARISH = "parish";
export const COUNTY = "county";
export const DIVISION = "division";
export const VILLAGE = "village";

// PROFILE
export const PROFILE = "profile";
export const USERS = "users";

// Water management configurable keys
export const KEY_SOURCE_OF_WATER = "source_of_water";
export const KEY_IRRIGATION_TECHNOLOGY = "irrigation_technology";
export const KEY_WATER_STORAGE_TYPE = "water_storage_type";
export const KEY_WATER_ABSTRACTION_METHOD = "water_abstraction_method";
export const KEY_QAULITY_OF_PIPES = "quality_of_pipes";
export const KEY_CROP_TYPE = "crop_type";

// Document type
export const DOC_TYPE_REPORT = "report";
export const DOC_TYPE_OTHER = "other";
export const DOC_TYPE_ATTACHMENT = "attachment";

const TODAY = "today";
const YESTERDAY = "yesterday";
const THIS_WEEK = "this_week";
const LAST_WEEK = "last_week";
const THIS_MONTH = "this_month";
const LAST_MONTH = "last_month";
const THIS_YEAR = "this_year";
const LAST_YEAR = "last_year";
const THIS_FY = "this_fy";
const LAST_FY = "last_fy";

export const START_DATE_FILTER = "created_at_min_date";
export const END_DATE_FILTER = "created_at_max_date";

export const preFilters = [
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_YEAR,
  THIS_FY,
  LAST_FY,
];

export const CONST_PRNS = "billings/prns";
export const CONST_MANUFACTURING = "agro-chemicals/manufacturing-facilities";
export const CONST_MARKET_DISTRIBUTION = "agro-chemicals/market-distributions";
export const CONST_SOURCE_TRACKING = "agro-chemicals/source-trackings";

export const CONST_MODULES = [
  { value: CONST_PRNS, label: "PRNs" },
  {
    value: CONST_MANUFACTURING,
    label: "Agrochemicals and Manufacturing Facilities",
  },
  {
    value: CONST_MARKET_DISTRIBUTION,
    label: "Agrochemicals and Market Distribution",
  },
  { value: CONST_SOURCE_TRACKING, label: "Agrochemicals and Source Tracking" },
];
