import { useEffect, useRef } from "react";
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import AppHeaderDropdown from "./AppHeaderDropdown";
import { useNav } from "../../context/NavContext";
import { CoaIMG } from "../../assets/img/Images";
import styled from "styled-components";
import { useProfile } from "../../hooks/profile/useProfile";
import { stringifyName } from "../../utils/helpers";
import { ShieldHalfOutline } from "@raresail/react-ionicons";
import { CircularProgress } from "@mui/material";

const AppHeader = () => {
  const headerRef = useRef<HTMLDivElement>();
  const { sidebarShow, setSidebarShow } = useNav();

  const { profile, loading } = useProfile();

  useEffect(() => {
    if (profile) {
      window.document.title = `NIFAMIS: ${stringifyName(profile)}`;
    }
  }, [profile]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      headerRef.current &&
        headerRef.current?.classList.toggle(
          "shadow-sm",
          document.documentElement.scrollTop > 0,
        );
    });
  }, []);

  return (
    <CHeader
      style={{ zIndex: 100 }}
      position="sticky"
      className="mb-4 p-0"
      ref={headerRef as any}
    >
      <CContainer className="border-bottom px-4" fluid>
        <CHeaderToggler
          onClick={() => setSidebarShow(!sidebarShow)}
          style={{ marginInlineStart: "-14px" }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>

        <div
          style={{ lineHeight: 1.2, marginLeft: 10 }}
          className="flexNullCenter gap10"
        >
          <img src={CoaIMG} width={35} />
          <div>
            <span className="extraBold font25">
              NIFA<span className="primaryColor">MIS</span>
            </span>
            <TaglineWrapper>
              National Integrated Food & Agricultural Management Information
              System
            </TaglineWrapper>
          </div>
        </div>

        <CHeaderNav className="ms-auto gap10">
          <DepartmentWrapper className="font12 flexCenter gap10 lightBg">
            <ShieldHalfOutline style={{ fontSize: 20 }} />
            <div
              style={{ height: "100%", width: 1, backgroundColor: "#ddd" }}
            />
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <div style={{ maxWidth: 180 }}>
                {profile?.profile?.department_name || "Department"}
              </div>
            )}
          </DepartmentWrapper>
          {/* <div className="p10">
            <CIcon icon={cilBell} size="lg" />
          </div> */}
        </CHeaderNav>
        <CHeaderNav>
          <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;

const TaglineWrapper = styled.div`
  font-size: 10px;
  @media (max-width: 960px) {
    display: none;
  }
`;
const DepartmentWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 0 10px;
  height: 40px;
  @media (max-width: 960px) {
    display: none;
  }
`;
