import { CSSProperties } from "react";
import { ButtonProps } from "../../types/common";
import { colors } from "../../utils/constants";

type SubmitButtonProp = {
  submitting: boolean;
  text?: string;
  disabled?: boolean;
  submitText?: string;
  style?: CSSProperties;
};

export const SubmitButton = ({
  submitting,
  text,
  submitText,
  disabled,
  style,
  ...rest
}: SubmitButtonProp) => {
  return (
    <button
      type="submit"
      className="btn btn-success primary-btn"
      disabled={submitting || disabled}
      style={{
        ...style,
        backgroundColor: colors.THEME_COLOR,
        color: "white",
        marginBottom: 10,
        marginTop: 10,
      }}
      {...rest}
    >
      {submitting ? submitText || "Submitting" : text || "Submit"}
    </button>
  );
};

export const PrimaryButton = (props: ButtonProps) => {
  const { title, loaderTitle, loading, type, style, ...rest } = props;

  return (
    <button
      className="btn mb-4 btn-primary btn-theme"
      style={{ ...style }}
      type={type}
      disabled={loading}
      {...rest}
    >
      {loading ? loaderTitle : title}
    </button>
  );
};
