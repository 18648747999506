import {
  Add,
  Group,
  ListSharp,
  Monitor,
  LocationOnOutlined,
  Description,
  SettingsAccessibility,
  ArrowBack,
  HomeWork,
  TaskAlt,
  CreditCard,
  Logout,
  Person,
  Key,
} from "@mui/icons-material";
import {
  URL_CONFIGURABLES,
  URL_ROLES,
  URL_USERS,
  URL_MASTER_DATA,
  URL_REGIONS,
  URL_DISTRICTS,
  URL_COUNTIES,
  URL_DIVISIONS,
  URL_PARISHES,
  URL_VILLAGES,
  URL_AUDIT_ACTIVITY,
  URL_DIRECTORATES,
  URL_DEPARTMENTS,
  URL_DEPARTMENT_DIVISIONS,
  URL_FEES,
  URL_PASSWORD_RESET,
  URL_SETTINGS,
} from "../utils/urls";
import { getForm, getRegister } from "../utils/helpers";
import {
  createAddOption,
  createRegisterOption,
} from "../components/menu/menuHelpers";
import { DIVISION } from "../utils/constants";
import {
  BuildOutline,
  PeopleOutline,
  SettingsOutline,
  ShieldHalfOutline,
} from "@raresail/react-ionicons";

export const accountSettings = [
  { label: "Account Profile", icon: Person, to: URL_SETTINGS },
  {
    label: "Change Password",
    icon: Key,
    to: getForm(URL_PASSWORD_RESET),
  },
  {
    label: "Logout",
    icon: Logout,
    to: "/logout",
  },
];

export const settings = () => [
  {
    label: "Back To Dashboard",
    icon: <ArrowBack />,
    to: "/",
  },
  {
    label: "Account Settings",
    key: "default",
    icon: <SettingsOutline />,
    to: URL_SETTINGS,
  },
  // User Management
  {
    label: "User Management",
    icon: <PeopleOutline />,
    to: getRegister(URL_USERS),
    children: [
      {
        label: "Add User",
        icon: <Add />,
        to: getForm(URL_USERS),
      },
      {
        label: "Users",
        icon: <Group />,
        to: getRegister(URL_USERS),
      },
    ],
  },
  // ROLES
  {
    label: "System Roles",
    icon: <SettingsAccessibility />,
    children: [
      {
        label: "New Role",
        icon: <Add />,
        to: getForm(URL_ROLES),
      },
      {
        label: "Roles Register",
        icon: <ListSharp />,
        to: getRegister(URL_ROLES),
      },
    ],
  },
  // Configurables
  {
    label: "Configurables",
    icon: <BuildOutline />,
    visible: true,
    children: [
      {
        label: "Create Configurable",
        icon: <Add />,
        to: getForm(URL_CONFIGURABLES),
      },
      {
        label: "All Configurables",
        icon: <Description />,
        to: getRegister(URL_CONFIGURABLES),
      },
    ],
  },
  //   Master Data
  {
    label: "Master Data",
    icon: <Monitor />,
    visible: true,
    children: [
      {
        label: "Add Master Data",
        icon: <Add />,
        visible: true,
        to: getForm(URL_MASTER_DATA),
      },
      {
        label: "Regions",
        icon: <LocationOnOutlined />,
        to: getRegister(URL_REGIONS),
      },
      {
        label: "Districts",
        icon: <LocationOnOutlined />,
        to: getRegister(URL_DISTRICTS),
      },
      {
        label: "Counties",
        icon: <LocationOnOutlined />,
        to: getRegister(URL_COUNTIES),
      },
      {
        label: "Divisions",
        icon: <LocationOnOutlined />,
        to: getRegister(URL_DIVISIONS),
      },
      {
        label: "Parishes",
        icon: <LocationOnOutlined />,
        to: getRegister(URL_PARISHES),
      },
      {
        label: "Villages",
        icon: <LocationOnOutlined />,
        to: getRegister(URL_VILLAGES),
      },
    ],
  },
  // OFFICES
  {
    label: "Offices",
    icon: <HomeWork />,
    children: [
      {
        label: "Directorates",
        icon: <TaskAlt />,
        children: [
          createAddOption(URL_DIRECTORATES, "Directorate"),
          createRegisterOption(URL_DIRECTORATES),
        ],
      },
      {
        label: "Departments",
        icon: <TaskAlt />,
        children: [
          createAddOption(URL_DEPARTMENTS, "Department"),
          createRegisterOption(URL_DEPARTMENTS),
        ],
      },
      {
        label: "Divisions",
        icon: <TaskAlt />,
        children: [
          createAddOption(URL_DEPARTMENT_DIVISIONS, DIVISION),
          createRegisterOption(URL_DEPARTMENT_DIVISIONS),
        ],
      },
    ],
  },
  // BILLINGS
  {
    label: "Fees",
    icon: <CreditCard />,
    children: [createAddOption(URL_FEES), createRegisterOption(URL_FEES)],
  },
  // AUDIT_ACTIVITY
  {
    label: "Audit Activity",
    icon: <ShieldHalfOutline />,
    to: getRegister(URL_AUDIT_ACTIVITY),
  },
];
