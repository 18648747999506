import React from "react";
import { LogoutRoute } from "../../../context/AuthContext";
import AdhocReports from "../reports/AdhocReports";
const ViewLayoutHandler = React.lazy(
  () => import("../../layouts/ViewLayoutHandler"),
);
const SettingsPage = React.lazy(() => import("../settings/SettingsPage"));
const DashboardPage = React.lazy(() => import("./DashboardPage"));
const GenericForm = React.lazy(() => import("../../layouts/GenericForm"));
const RegisterLayoutHandler = React.lazy(
  () => import("../../layouts/RegisterLayoutHandler"),
);

const DashboardRoutes = [
  {
    path: "/",
    component: <DashboardPage />,
    exact: true,
  },
  {
    path: "/register/:id",
    component: <RegisterLayoutHandler />,
    exact: true,
  },
  {
    path: "/register/:id/:status",
    component: <RegisterLayoutHandler />,
    exact: true,
  },
  {
    path: "/:source_id/create",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/:source_id/update/:id",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/:source_id/view/:id",
    component: <ViewLayoutHandler />,
    exact: true,
  },
  {
    path: "/settings",
    component: <SettingsPage />,
    exact: true,
  },

  // Reports
  {
    path: "/reports/adhoc-reports",
    component: <AdhocReports />,
    exact: true,
  },

  // Logout
  {
    path: "/logout",
    component: <LogoutRoute />,
    exact: true,
  },
];

export default DashboardRoutes;
